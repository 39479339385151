// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-page-tsx": () => import("./../../../src/templates/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-concept-page-tsx": () => import("./../../../src/templates/concept-page.tsx" /* webpackChunkName: "component---src-templates-concept-page-tsx" */),
  "component---src-templates-contact-page-tsx": () => import("./../../../src/templates/contact-page.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-familiarisation-page-tsx": () => import("./../../../src/templates/familiarisation-page.tsx" /* webpackChunkName: "component---src-templates-familiarisation-page-tsx" */),
  "component---src-templates-imprint-page-tsx": () => import("./../../../src/templates/imprint-page.tsx" /* webpackChunkName: "component---src-templates-imprint-page-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../../../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-location-page-tsx": () => import("./../../../src/templates/location-page.tsx" /* webpackChunkName: "component---src-templates-location-page-tsx" */),
  "component---src-templates-privacy-page-tsx": () => import("./../../../src/templates/privacy-page.tsx" /* webpackChunkName: "component---src-templates-privacy-page-tsx" */),
  "component---src-templates-qualification-page-tsx": () => import("./../../../src/templates/qualification-page.tsx" /* webpackChunkName: "component---src-templates-qualification-page-tsx" */),
  "component---src-templates-room-design-page-tsx": () => import("./../../../src/templates/room-design-page.tsx" /* webpackChunkName: "component---src-templates-room-design-page-tsx" */),
  "component---src-templates-schedule-page-tsx": () => import("./../../../src/templates/schedule-page.tsx" /* webpackChunkName: "component---src-templates-schedule-page-tsx" */),
  "component---src-templates-toilet-training-page-tsx": () => import("./../../../src/templates/toilet-training-page.tsx" /* webpackChunkName: "component---src-templates-toilet-training-page-tsx" */)
}

